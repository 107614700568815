<template>
	<div style="text-align: center;padding: 30px 10px;">
		<van-icon name="checked" size="110" color="orange"/>
		
		<p style="margin: 20px 0 80px 0;">在线支付成功，请等待系统处理</p>
		
	</div>
</template>

<script>
	export default {
		name: 'deposit_success'
	}
</script>